import * as React from 'react'
import * as Types from '../declarations'
import { versionFromHostname } from '../lib/withVersion'

// Define the interface for the context
interface VersionContext {
	version: Types.VERSION
	setVersion?: (version: Types.VERSION) => void
}

// Create the context
export const VersionContext = React.createContext<VersionContext>({
	version: Types.VERSION.TREKKING,
})

const VersionProvider = ({ children }: { children: React.ReactNode }) => {
	// Use the existing function for version detection based on hostname
	const getVersionFromHostname = (): Types.VERSION => {
		return versionFromHostname(window.location.hostname)
	}

	// Set the initial state based on the hostname
	const [version, setVersion] = React.useState<Types.VERSION>(
		getVersionFromHostname,
	)

	return (
		<VersionContext.Provider value={{ version, setVersion }}>
			{children}
		</VersionContext.Provider>
	)
}

// export { VersionContext, VersionProvider }

export default VersionProvider
