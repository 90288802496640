// import { getVersion } from './getVersion'
import {
	// GetServerSideProps,
	GetServerSidePropsContext,
	// GetServerSideProps,
} from 'next'
import * as Types from '../declarations'

export const versionFromHostname = (
	hostname: string | undefined,
): Types.VERSION => {
	switch (hostname?.split(':')?.[0]) {
		case 'www.guidedpeaks.com':
		case 'guidedpeaks.com':
		case 'guidedpeaks.local':
		case 'www.guidedpeaks.local':
		case 'render-guidedpeaks.samt.st':
			return Types.VERSION.PEAKS
		case 'www.guidedtrekking.com':
		case 'guidedtrekking.com':
		case 'guidedtrekking.local':
		case 'www.guidedtrekking.local':
		case 'render-guidedtrekking.samt.st':
		default:
			return Types.VERSION.TREKKING
	}
}

export const getVersion = (hostname?: string): Types.VERSION => {
	const urlVersion = versionFromHostname(hostname)

	// const envVersion = process?.env?.VERSION as Types.VERSION

	// const version = envVersion || urlVersion

	// return version
	return urlVersion
}

export const withVersion = (
	getServerSidePropsFunc: (
		context: GetServerSidePropsContext & { version: Types.VERSION },
	) => unknown,
) => {
	return async (
		context: GetServerSidePropsContext & { version: Types.VERSION },
	) => {
		// will work when used for getserverside [and probably getclientside?] but not for get initial as no url/host - thus will rely on env var else default
		context.version = getVersion(
			// @ts-ignore
			context?.req?.headers.host,
		)
		return await getServerSidePropsFunc(context)
	}
}
